:root  {
    font-size:  1.35rem;
  }
  @media only screen and (max-width: 600px)  {
    :root  {
    font-size:  1.2rem;
  }
  }
  *, :after, :before  {
    box-sizing:  border-box;
  }
  html  {
    scroll-behavior:  smooth;
  }
  ::-webkit-scrollbar  {
    width:  15px;
  }
  ::-webkit-scrollbar-track  {
    background-color:  #121212;
    border-radius:  100vw;
    margin-block:  .5em;
  }
  ::-webkit-scrollbar-thumb  {
    background-color:  #2d2d2d;
    border-radius:  100vw;
  }
  ::-webkit-scrollbar-thumb:hover  {
    background-color:  #3f3f3f;
  }
  @supports (scrollbar-color: #2d2d2d #121212)  {
    *  {
    scrollbar-color:  #2d2d2d #121212;
  }
  /* img {
    display: block;
    width: 500px;
    height: 600px;
    margin-bottom: 2%;
    margin-right: auto;
    margin-left: auto;
    } */
  }
  body  {
    margin:  0;
    padding:  5px 0;
    background-color:  #000;
    font-family: 'Antonio',  'Arial Narrow',  'Avenir Next Condensed',  sans-serif;
    line-height:  1.5;
    color:  #ddbbff;
  }
  a  {
    text-decoration:  underline;
    color:  #f5f6fa;
  }
  @keyframes blink  {
    0%  {
    opacity:  0;
  }
  49% {
    opacity:  0;
  }
  50%  {
    opacity:  1;
  }
  }@keyframes colorchange  {
    0%  {
    color:  #ddbbff;
  }
  25%  {
    color:  #ddbbff;
  }
  50%  {
    color:  #ddbbff;
  }
  75%  {
    color:  #ddbbff;
  }
  80%  {
    color:  #000;
  }
  90%  {
    color:  #000;
  }
  100%  {
    color:  #fec;
  }
  }.wrap  {
    display:  flex;
    margin:  0 auto;
    padding:  0 10px 0 5px;
    overflow:  hidden;
    max-width:  1340px;
  }
  #stretch .wrap  {
    max-width:  100%;
  }
  .scroll-top  {
    display:  block;
  }
  .scroll-top a  {
    display:  none;
    width:  190px;
    height:  150px;
    position:  fixed;
    bottom:  0;
    text-decoration:  none;
    text-transform:  uppercase;
    text-align:  right;
    border-top:  8px solid #000;
    border-bottom:  8px solid #000;
    padding:  20px 10px 20px 2px;
    background-color:  #9944ff;
    font-size:  .96rem;
    font-weight:  bold;
    color:  #000;
  }
  .left-frame-top,  .left-frame  {
    width:  190px;
    min-width:  190px;
    text-align:  right;
    font-size:  .88rem;
    font-weight:  bold;
    color:  #000;
  }
  .left-frame-top  {
    background-color:  #ddbbff;
    border-radius:  0 0 0 60px;
  }
  .left-frame-top a,  .left-frame a  {
    text-decoration:  none;
    color:  #000;
  }
  .left-frame  {
    display:  flex;
    flex-direction:  column;
    justify-content:  space-between;
    padding-top:  160px;
    background-color:  #ddbbff;
    border-radius:  60px 0 0 0;
  }
  .panel-1 a  {
    display:  block;
    background-color:  #9944ff;
    padding:  82px 10px 15px 0;
    text-decoration:  none;
    color:  #000;
    -webkit-touch-callout:  none;
    -webkit-user-select:  none;
    -ms-user-select:  none;
    user-select:  none;
  }
  .panel-1 a:active,  .panel-1 a:focus  {
    filter:  brightness(70%);
    text-decoration:  none;
    border-bottom:  none;
    outline:  none;
  }
  .panel-2  {
    padding:  15px 10px 0 0;
  }
  .right-frame-top  {
    flex:  1;
    background-color:  #000;
  }
  .banner  {
    padding:  0 0 15px 5px;
    text-align:  right;
    text-transform:  uppercase;
    line-height:  1.2;
    font-size:  clamp(1.2rem,  1.3rem + 2.6vw,  3.1rem);
    color:  #f5f6fa;
  }
  .banner a  {
    color:  #f5f6fa;
    text-decoration:  none;
  }
  @media only screen and (max-width: 500px)  {
    .banner  {
    padding:  5px 0 10px 3px;
    font-size:  1.2rem;
  }
  }
  .data-cascade-button-group  {
    display:  flex;
    justify-content:  flex-end;
    column-gap:  15px;
  }
  @media only screen and (max-width: 610px)  {
    .data-cascade-button-group  {
    column-gap:  0;
  }
  }
  .cascade-wrapper  {
    flex:  1;
    padding-right:  10px;
  }
  @media only screen and (min-width: 1080px)  {
    .cascade-wrapper  {
    padding-left:  25px;
  }
  }
  @media only screen and (max-width: 760px)  {
    .cascade-wrapper  {
    display:  none;
  }
  }
  .data-cascade  {
    max-width:  1200px;
    font-size:  .8rem;
    line-height:  22px;
  }
  .row-1,  .row-2,  .row-3,  .row-4,  .row-5,  .row-6  {
    display:  flex;
    justify-content:  space-between;
    overflow:  hidden;
    height:  22px;
    color:  #000;
  }
  .data-cascade .row-1  {
    animation:  colorchange 2s infinite;
    animation-delay:  .1s;
  }
  .data-cascade .row-2  {
    animation:  colorchange 3s infinite;
    animation-delay:  .2s;
  }
  .data-cascade .row-3  {
    animation:  colorchange 2s infinite;
    animation-delay:  .3s;
  }
  .data-cascade .row-4  {
    animation:  colorchange 2s infinite;
    animation-delay:  .4s;
  }
  .data-cascade .row-5  {
    animation:  colorchange 3s infinite;
    animation-delay:  .5s;
  }
  .data-cascade .row-6  {
    animation:  colorchange 2s infinite;
    animation-delay:  .6s;
  }
  .data-cascade#niagara .row-1  {
    animation:  colorchange 2s infinite;
    animation-delay:  .1s;
  }
  .data-cascade#niagara .row-2  {
    animation:  colorchange 2s infinite;
    animation-delay:  .2s;
  }
  .data-cascade#niagara .row-3  {
    animation:  colorchange 2s infinite;
    animation-delay:  .3s;
  }
  .data-cascade#niagara .row-4  {
    animation:  colorchange 2s infinite;
    animation-delay:  .4s;
  }
  .data-cascade#niagara .row-5  {
    animation:  colorchange 2s infinite;
    animation-delay:  .5s;
  }
  .data-cascade#niagara .row-6  {
    animation:  colorchange 2s infinite;
    animation-delay:  .6s;
  }
  .data-cascade#orinoco .row-1  {
    animation:  colorchange 2s infinite;
    animation-delay:  .1s;
  }
  .data-cascade#orinoco .row-2  {
    animation:  colorchange 2s infinite;
    animation-delay:  .2s;
  }
  .data-cascade#orinoco .row-3  {
    animation:  colorchange 3s infinite;
    animation-delay:  .3s;
  }
  .data-cascade#orinoco .row-4  {
    animation:  colorchange 3s infinite;
    animation-delay:  .4s;
  }
  .data-cascade#orinoco .row-5  {
    animation:  colorchange 2s infinite;
    animation-delay:  .5s;
  }
  .data-cascade#orinoco .row-6  {
    animation:  colorchange 2s infinite;
    animation-delay:  .6s;
  }
  .data-cascade#frozen .row-1  {
    animation:  none;
    color:  #ddbbff;
  }
  .data-cascade#frozen .row-2  {
    animation:  none;
    color:  #ddbbff;
  }
  .data-cascade#frozen .row-3  {
    animation:  none;
    color:  #ddbbff;
  }
  .data-cascade#frozen .row-4  {
    animation:  none;
    color:  #9944ff;
  }
  .data-cascade#frozen .row-5  {
    animation:  none;
    color:  #fec;
  }
  .data-cascade#frozen .row-6  {
    animation:  none;
    color:  #ddbbff;
  }
  .dc1,  .dc2,  .dc3,  .dc4,  .dc5,  .dc6,  .dc7,  .dc8,  .dc9,  .dc10,  .dc11,  .dc12,  .dc13,  .dc14,  .grid,  .grid-2,  .grid-1,  .grid-3,  .grid-4  {
    flex:  1;
    overflow:  hidden;
    text-align:  right;
    white-space:  nowrap;
  }
  .button-col,  .button-col-1,  .button-col-2  {
    width:  190px;
  }
  .button-col div:last-of-type,  .button-col-1 div:last-of-type,  .button-col-2 div:last-of-type  {
    margin-bottom:  -15px;
  }
  @media only screen and (max-width: 610px)  {
    .button-col,  .button-col-1,  .button-col-2  {
    width:  auto;
    border-left:  15px solid black;
  }
  }
  .button a  {
    display:  block;
    width:  190px;
    height:  58px;
    margin-bottom:  15px;
    padding:  20px 25px 0 0;
    background-color:  #552255;
    border-radius:  26px;
    text-align:  right;
    line-height:  normal;
    text-decoration:  none;
    text-transform:  uppercase;
    font-weight:  bold;
    color:  #000;
    -webkit-touch-callout:  none;
    -webkit-user-select:  none;
    -ms-user-select:  none;
    user-select:  none;
  }
  .button#top-left a  {
    background-color: #f5f6fa;
  }
  .button#bottom-left a  {
    background-color:  #9944ff;
  }
  .button#top-right a  {
    background-color: #552255;
  }
  .button#bottom-right a  {
    background-color: #ddbbff;
  }
  .button a:active,  .button a:focus  {
    filter:  brightness(70%);
    border:  none;
    outline:  none;
    animation:  none;
  }
  @media only screen and (max-width: 500px)  {
    .button a  {
    height:  44px;
    padding-top:  10px;
    width:  142px;
    border-radius:  20px;
    font-size:  1rem;
  }
  }
  @media only screen and (max-width: 388px)  {
    .button a  {
    width:  134px;
    font-size:  .85rem;
  }
  }
  .runner  {
    display:  flex;
    height:  50px;
  }
  .top-corner-bg  {
    width:  160px;
    height:  50px;
    background:  linear-gradient(to top right,  #ddbbff 50%,  #000 50%);
  }
  .top-corner  {
    width:  160px;
    height:  50px;
    background-color:  #000;
    border-radius:  0 0 0 30px;
  }
  .block-2  {
    width:  140px;
  }
  .block-3  {
    width:  140px;
  }
  .block-4  {
    flex: 1;
    padding-top:  30px;
  }
  .block-4b  {
    flex: 1;
    padding-top:  12px;
  }
  .block-4-floatbar  {
    width:  260px;
    height:  8px;
    background-color:  #ddbbff;
  }
  .bar-panel  {
    display:  flex;
    height:  22px;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-4,  .bar-6,  .bar-7,  .bar-8,  .bar-9  {
    height:  22px;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-4,  .bar-6,  .bar-7,  .bar-8,  .bar-9,  .bar-4-inside,  .bar-9-inside,  .block-4-floatbar  {
    transition:  width 1s;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-6,  .bar-7,  .bar-8  {
    border-right:  8px solid #000;
  }
  .bar-1,  .bar-6  {
    width:  160px;
    background-color:  #ddbbff;
  }
  .bar-2,  .bar-7  {
    width:  140px;
    background-color:  #9944ff;
  }
  .bar-3,  .bar-8  {
    width:  140px;
    background-color:  #f5f6fa;
  }
  .bar-4  {
    flex:  1;
    background-color:  #552255;
    padding-top:  10px;
  }
  .bar-4-inside  {
    width:  200px;
    height:  12px;
    background-color:  #000;
    border-radius:  0 10px 0 0;
  }
  .bar-9  {
    flex:  1;
    background-color:  #552255;
  }
  .bar-9-inside  {
    width:  200px;
    height:  12px;
    background-color:  #000;
    border-radius:  0 0 10px 0;
  }
  #gap  {
    margin-top:  10px;
  }
  .panel-1,  .panel-3,  .panel-4,  .panel-5,  .panel-6,  .panel-7,  .panel-8  {
    border-bottom:  8px solid #000;
  }
  .panel-3  {
    padding:  0 10px 15px 0;
  }
  .panel-4  {
    height:  120px;
    padding:  15px 10px 0 0;
    background-color:  #9944ff;
    justify-content:  space-around;
  }
  .panel-5  {
    height:  350px;
    padding:  15px 10px 0 0;
    background-color:  #552255;
  }
  .panel-6  {
    height:  250px;
    padding:  15px 10px 0 0;
    background-color:  #f5f6fa;
  }
  .panel-7  {
    height:  380px;
    padding:  20px 10px 0 0;
    background-color:  #ddbbff;
  }
  .panel-8  {
    padding:  50px 10px 50px 0;
    background-color:  #f5f6fa;
  }
  .panel-9  {
    height:  190px;
    padding:  15px 10px 0 0;
    background-color:  #ddbbff;
  }
  .panel-10  {
    height:  350px;
    border-top:  8px solid #000;
    background-color:  #552255;
    padding:  15px 10px 0 0;
  }
  .right-frame  {
    flex:  1;
    background-color:  #000;
  }
  .corner-bg  {
    width:  160px;
    height:  50px;
    background:  linear-gradient(to bottom right,  #ddbbff 50%,  #000 50%);
  }
  .corner  {
    width:  160px;
    height:  50px;
    background-color:  #000;
    border-radius:  30px 0 0 0;
  }
  .content  {
    padding:  0 0 25px 50px;
    background-color:  #000;
  }
  .content > *:first-child  {
    margin-top:  0;
  }
  .content img  {
    max-width:  100%;
    height:  auto;
  }
  
.footer  {
  padding:  0 0 25px 50px;
  background-color:  #000;
}
.footer > *:first-child  {
  margin-top:  0;
}
.footer img  {
  max-width:  100%;
  height:  auto;
}

  .flexbox  {
    display:  flex;
    column-gap:  2vw;
    flex-wrap:  wrap;
  }
  .col  {
    flex:  1 1 300px;
  }
  h1,  h2,  h3  {
    margin:  30px 0;
    font-weight:  normal;
    text-transform:  uppercase;
    line-height:  1.2;
    color:  #f5f6fa;
  }
  h1  {
    font-size:  clamp(1.6rem,  1.6rem + 2.7vw,  3.34rem);
    text-align:  right;
  }
  h2  {
    font-size:  clamp(1.2rem,  1.2rem + 2vw,  2rem);
  }
  h3  {
    font-size:  clamp(1.1rem,  1rem + 2vw,  1.8rem) }
  p.caption  {
    margin-top:  10px;
    margin-bottom:  0;
    text-align:  center;
    font-size:  .88rem;
  }
  p.indent  {
    padding-left:  40px;
  }
  p.postmeta  {
    margin-top:  0;
    text-align:  right;
    font-size:  clamp(1rem,  .85rem + 1.4vw,  1.6rem);
    text-transform:  uppercase;
  }
  .logging h1  {
    margin-bottom:  0;
  }
  code  {
    font-size:  .8rem;
    color:  #552255;
  }
  hr  {
    margin:  30px 0;
    height:  6px;
    border:  none;
    background-color:  #ddbbff;
    border-radius:  3px;
  }
  .disc  {
    margin:  30px auto;
    width:  20px;
    height:  20px;
    border-radius:  10px;
  }
  blockquote  {
    margin:  30px 40px;
    border-left:  4px solid #ddbbff;
    padding-left:  20px;
  }
  .nomar  {
    margin:  0 !important;
  }
  .go-center  {
    text-align:  center !important;
  }
  .go-right  {
    text-align:  right !important;
  }
  .go-left  {
    text-align:  left !important;
  }
  .go-big  {
    font-size:  1.3rem;
  }
  .go-bigger  {
    font-size:  1.5rem;
  }
  .uppercase  {
    text-transform:  uppercase;
  }
  .strike  {
    text-decoration:  line-through;
  }
  .now  {
    white-space:  nowrap;
  }
  .blink-slower  {
    animation:  blink 3s infinite;
    animation-delay:  1s;
  }
  .blink  {
    animation:  blink 2s infinite;
    animation-delay:  1s;
  }
  .blink-faster  {
    animation:  blink 1s infinite;
    animation-delay:  1s;
  }
  a:hover  {
    animation:  none;
  }
  .pics-right  {
    float:  right;
    margin:  10px 0 20px 20px;
  }
  .pics-left  {
    float:  left;
    margin:  10px 20px 20px 0;
  }
  .pics  {
    display:  block;
    margin:  30px auto 0 auto;
  }
  .border  {
    padding:  10px;
    border:  2px solid #ddbbff;
  }
  .lcars-list  {
    list-style:  none;
  }
  .lcars-list li  {
    position:  relative;
    padding:  0 0 5px 42px;
    font-size:  1.3rem;
  }
  .lcars-list li::before  {
    content:  '';
    display:  block;
    width:  30px;
    height:  20px;
    border-radius:  50%;
    background-color:  #ddbbff;
    position:  absolute;
    top:  13px;
    left:  0;
  }
  @media only screen and (max-width: 600px)  {
    .lcars-list  {
    margin-left:  0;
    padding-left:  0;
  }
  .lcars-list li::before  {
    top:  10px;
  }
  }
  .buttons  {
    margin-top:  35px;
    display:  flex;
    flex-wrap:  wrap;
  }
  .jc-space-between  {
    justify-content:  space-between;
  }
  .jc-center  {
    justify-content:  center;
  }
  .jc-flex-end  {
    justify-content:  flex-end;
  }
  .jc-space-around  {
    justify-content:  space-around;
  }
  .jc-space-evenly  {
    justify-content:  space-evenly;
  }
  .buttons a  {
    display:  block;
    margin:  0 8px 16px 8px;
    width:  200px;
    height:  60px;
    padding:  20px 25px 0 0;
    background-color:  #552255;
    border-radius:  26px;
    text-align:  right;
    font-size:  16px;
    line-height:  1.2;
    text-decoration:  none;
    font-weight:  bold;
    text-transform:  uppercase;
    color:  #f5f6fa;
    -webkit-touch-callout:  none;
    -webkit-user-select:  none;
    -ms-user-select:  none;
    user-select:  none;
  }
  .buttons a:active,  .buttons a:focus  {
    filter:  brightness(70%);
    outline:  none;
  }
  .buttons a.two-rows  {
    font-size:  21px;
    padding-top:  2px;
  }
  .buttons a.long  {
    padding:  20px 0 0 0 !important;
    text-align:  center !important;
  }
  .sidebar-buttons a  {
    display:  block;
    text-decoration:  none;
    text-align:  right;
    border-bottom:  8px solid #000;
    padding:  20px 10px 20px 2px;
    background-color:  #ddbbff;
    text-transform:  uppercase;
    color:  #000;
  }
  .sidebar-buttons a:active,  .sidebar-buttons a:focus  {
    filter:  brightness(80%);
    outline:  none;
  }
  @media only screen and (max-width: 500px)  {
    .sidebar-buttons a  {
    text-align:  center;
    padding:  10px 0;
    border-bottom-width:  5px;
  }
  }
  .lcars-bar  {
    margin:  40px auto;
    height:  20px;
    background-color:  #000;
    border-right:  18px solid #552255;
    border-left:  18px solid #552255;
    border-radius:  9px;
    padding:  0 5px;
  }
  .lcars-bar-inner  {
    height:  20px;
    background-color:  #ddbbff;
  }
  .lcars-bar-slice-top  {
    margin:  40px auto -30px auto;
    width:  180px;
    height:  8px;
    background-color:  #ddbbff;
  }
  .lcars-bar-cutout  {
    margin:  0 auto;
    width:  180px;
    height:  10px;
    background-color:  #000;
  }
  .lcars-bar-slice-bottom  {
    margin:  -30px auto 0 auto;
    width:  180px;
    height:  5px;
    background-color:  #ddbbff;
  }
  .lcars-text-bar  {
    display:  flex;
    position:  relative;
    margin:  32px 0;
    overflow:  visible;
    border-radius:  14px;
    height:  32px;
    background-color:  #ddbbff;
    border-right:  28px solid #552255;
    border-left:  28px solid #552255;
  }
  .the-end  {
    justify-content:  flex-end;
  }
  .lcars-text-bar span  {
    position:  absolute;
    top:  -4px;
    background-color:  #000;
    height:  40px;
    overflow:  visible;
    padding:  0 10px;
    font-size:  1.64rem;
    line-height:  34px;
    text-transform:  uppercase;
    color:  #f5f6fa }
  .lcars-text-bar::before  {
    content:  '';
    background-color:  #000;
    display:  block;
    width:  10px;
    height:  32px;
    position:  absolute;
    top:  0;
    left:  0;
    overflow:  hidden;
  }
  .lcars-text-bar::after  {
    content:  '';
    background-color:  #000;
    display:  block;
    width:  10px;
    height:  32px;
    position:  absolute;
    top:  0;
    right:  0;
    overflow:  hidden;
  }
  @media only screen and (max-width: 600px)  {
    .lcars-text-bar  {
    border-radius:  10px;
    height:  24px;
    border-right-width:  20px;
    border-left-width:  20px;
  }
  .lcars-text-bar span  {
    position:  absolute;
    top:  -6px;
    height:  30px;
    padding:  0 5px;
    font-size:  1.34rem;
    line-height:  32px;
  }
  .lcars-text-bar::before,  .lcars-text-bar::after  {
    width:  5px;
    height:  24px;
  }
  }
  footer  {
    display:  flex;
    bottom:  0;
    margin:  50px auto 0 auto;
    padding:  12px 0;
    background:  linear-gradient(#552255 50%,  #ddbbff 50%);
    border-radius:  0 20px 20px 0;
    overflow:  hidden;
    position:  relative;
    z-index:  1;
  }
  footer::before  {
    content:  '';
    background-color:  #000;
    display:  block;
    width:  5px;
    height:  100vh;
    position:  absolute;
    top:  0;
    left:  25%;
    overflow:  hidden;
  }
  footer::after  {
    content:  '';
    background-color:  #000;
    display:  block;
    width:  2px;
    height:  100vh;
    position:  absolute;
    top:  0;
    left:  -1px;
    overflow:  hidden;
  }
  .footer-panel  {
    width:  12%;
    max-width:  130px;
    height:  100px;
    margin:  auto;
    padding:  10px 0 0 1%;
    background-color:  #f5f6fa;
    border-top:  5px solid #000;
    border-bottom:  5px solid #000;
    font-size:  .85rem;
    font-weight:  bold;
    color:  #000;
  }
  .footer-inside  {
    flex:  1;
    min-height:  180px;
    background-color:  #000;
    border-radius:  0 20px 20px 0;
    padding:  1.5rem 1rem;
    z-index:  2;
  }
  .footer-inside::before  {
    content:  '';
    background-color:  #552255;
    display:  block;
    width:  15%;
    min-width:  60px;
    height:  10px;
    position:  absolute;
    top:  17px;
    left:  1px;
    overflow:  hidden;
  }
  .footer-inside::after  {
    content:  '';
    background-color:  #ddbbff;
    display:  block;
    width:  15%;
    min-width:  60px;
    height:  10px;
    position:  absolute;
    bottom:  12px;
    left:  1px;
    overflow:  hidden;
  }
  .footer-text  {
    display:  flex;
    flex-direction:  column;
    min-height:  146px;
    align-items:  center;
    justify-content:  center;
    gap:  20px;
    text-align:  center;
    font-size:  .94rem;
  }
  .footer-text > *  {
    margin:  0;
    justify-content:  center;
  }
  @media only screen and (max-width: 600px)  {
    footer :before  {
      left:  40%;
    }
  }
  /* .footer  {
    margin:  0 auto;
    font-size:  .94rem;
    text-align:  center;
  } */
  .space-white,  .oc-space-white::before  {
    background-color:  #f5f6fa !important;
  }
  .go-space-white  {
    color:  #f5f6fa !important;
  }
  .violet-creme,  .oc-violet-creme::before  {
    background-color:  #dbf !important;
  }
  .go-violet-creme  {
    color:  #dbf !important;
  }
  .magenta,  .oc-magenta::before  {
    background-color:  #c49 !important;
  }
  .go-magenta  {
    color:  #c49 !important;
  }
  .green,  .oc-green::before  {
    background-color:  #3c9 !important;
  }
  .go-green  {
    color:  #3c9 !important;
  }
  .blue,  .oc-blue::before  {
    background-color:  #45f !important;
  }
  .go-blue  {
    color:  #45f !important;
  }
  .yellow,  .oc-yellow::before  {
    background-color:  #fc3 !important;
  }
  .go-yellow  {
    color:  #fc3 !important;
  }
  .sunflower,  .oc-sunflower::before  {
    background-color:  #fc6 !important;
  }
  .go-sunflower  {
    color:  #fc6 !important;
  }
  .violet,  .oc-violet::before  {
    background-color:  #94f !important;
  }
  .go-violet  {
    color:  #94f !important;
  }
  .orange,  .oc-orange::before  {
    background-color:  #9944ff !important;
  }
  .go-orange  {
    color:  #9944ff !important;
  }
  .african-violet,  .oc-african-violet::before  {
    background-color:  #c8f !important;
  }
  .go-african-violet  {
    color:  #c8f !important;
  }
  .red,  .oc-red::before  {
    background-color:  #d44 !important;
  }
  .go-red  {
    color:  #d44 !important;
  }
  .almond,  .oc-almond::before  {
    background-color:  #ffaa90 !important;
  }
  .go-almond  {
    color:  #ffaa90 !important;
  }
  .almond-creme,  .oc-almond-creme::before  {
    background-color:  #fba !important;
  }
  .go-almond-creme  {
    color:  #fba !important;
  }
  .bluey,  .oc-bluey::before  {
    background-color:  #78f !important;
  }
  .go-bluey  {
    color:  #78f !important;
  }
  .gray,  .oc-gray::before  {
    background-color:  #668 !important;
  }
  .go-gray  {
    color:  #668 !important;
  }
  .sky,  .oc-sky::before  {
    background-color:  #aaf !important;
  }
  .go-sky  {
    color:  #aaf !important;
  }
  .ice,  .oc-ice::before  {
    background-color:  #8cf !important;
  }
  .go-ice  {
    color:  #8cf !important;
  }
  .gold,  .oc-gold::before  {
    background-color:  #fa0 !important;
  }
  .go-gold  {
    color:  #fa0 !important;
  }
  .mars,  .oc-mars::before  {
    background-color:  #f20 !important;
  }
  .go-mars  {
    color:  #f20 !important;
  }
  .peach,  .oc-peach::before  {
    background-color:  #f86 !important;
  }
  .go-peach  {
    color:  #f86 !important;
  }
  .butterscotch,  .oc-butterscotch::before  {
    background-color:  #f96 !important;
  }
  .go-butterscotch  {
    color:  #f96 !important;
  }
  .tomato,  .oc-tomato::before  {
    background-color:  #f55 !important;
  }
  .go-tomato  {
    color:  #f55 !important;
  }
  .lilac,  .oc-lilac::before  {
    background-color:  #c3f !important;
  }
  .go-lilac  {
    color:  #c3f !important;
  }
  .evening,  .oc-evening::before  {
    background-color:  #25f !important;
  }
  .go-evening  {
    color:  #25f !important;
  }
  .midnight,  .oc-midnight::before  {
    background-color:  #11e !important;
  }
  .go-midnight  {
    color:  #11e !important;
  }
  .ghost,  .oc-ghost::before  {
    background-color:  #8bf !important;
  }
  .go-ghost  {
    color:  #8bf !important;
  }
  .wheat,  .oc-wheat::before  {
    background-color:  #ca8 !important;
  }
  .go-wheat  {
    color:  #ca8 !important;
  }
  .roseblush,  .oc-roseblush::before  {
    background-color:  #c66 !important;
  }
  .go-roseblush  {
    color:  #c66 !important;
  }
  .honey,  .oc-honey::before  {
    background-color:  #f5f6fa !important;
  }
  .go-honey  {
    color:  #f5f6fa !important;
  }
  .cardinal,  .oc-cardinal::before  {
    background-color:  #c23 !important;
  }
  .go-cardinal  {
    color:  #c23 !important;
  }
  .pumpkinshade,  .oc-pumpkinshade::before  {
    background-color:  #f74 !important;
  }
  .go-pumpkinshade  {
    color:  #f74 !important;
  }
  .tangerine,  .oc-tangerine::before  {
    background-color:  #f83 !important;
  }
  .go-tangerine  {
    color:  #f83 !important;
  }
  .martian,  .oc-martian::before  {
    background-color:  #9d6 !important;
  }
  .go-martian  {
    color:  #9d6 !important;
  }
  .moonbeam,  .oc-moonbeam::before  {
    background-color:  #ccdeff !important;
  }
  .go-moonbeam  {
    color:  #ccdeff !important;
  }
  .cool,  .oc-cool::before  {
    background-color:  #58f !important;
  }
  .go-cool  {
    color:  #58f !important;
  }
  .galaxy,  .oc-galaxy::before  {
    background-color:  #444a77 !important;
  }
  .go-galaxy  {
    color:  #444a77 !important;
  }
  .moonshine,  .oc-moonshine::before  {
    background-color:  #def !important;
  }
  .go-moonshine  {
    color:  #def !important;
  }
  .october-sunset,  .oc-october-sunset::before  {
    background-color:  #ddbbff !important;
  }
  .go-october-sunset  {
    color:  #ddbbff !important;
  }
  .harvestgold,  .oc-harvestgold::before  {
    background-color:  #552255 !important;
  }
  .go-harvestgold  {
    color:  #552255 !important;
  }
  .butter,  .oc-butter::before  {
    background-color:  #fec !important;
  }
  .go-butter  {
    color:  #fec !important;
  }
  .c43,  .oc-c43::before  {
    background-color:  #58e !important;
  }
  .go-c43  {
    color:  #58e !important;
  }
  .c44,  .oc-c44::before  {
    background-color:  #8ff !important;
  }
  .go-c44  {
    color:  #8ff !important;
  }
  .c45,  .oc-c45::before  {
    background-color:  #344470 !important;
  }
  .go-c45  {
    color:  #344470 !important;
  }
  .c46,  .oc-c46::before  {
    background-color:  #455580 !important;
  }
  .go-c46  {
    color:  #455580 !important;
  }
  .c47,  .oc-c47::before  {
    background-color:  #79d !important;
  }
  .go-c47  {
    color:  #79d !important;
  }
  .c48,  .oc-c48::before  {
    background-color:  #6cf !important;
  }
  .go-c48  {
    color:  #6cf !important;
  }
  .c49,  .oc-c49::before  {
    background-color:  #9cf !important;
  }
  .go-c49  {
    color:  #9cf !important;
  }
  .c50,  .oc-c50::before  {
    background-color:  #ff3500 !important;
  }
  .go-c50  {
    color:  #ff3500 !important;
  }
  .c51,  .oc-c51::before  {
    background-color:  #525 !important;
  }
  .go-c51  {
    color:  #525 !important;
  }
  .c52,  .oc-c52::before  {
    background-color:  #636 !important;
  }
  .go-c52  {
    color:  #636 !important;
  }
  .c53,  .oc-c53::before  {
    background-color:  #747 !important;
  }
  .go-c53  {
    color:  #747 !important;
  }
  .c54,  .oc-c54::before  {
    background-color:  #858 !important;
  }
  .go-c54  {
    color:  #858 !important;
  }
  .c55,  .oc-c55::before  {
    background-color:  #969 !important;
  }
  .go-c55  {
    color:  #969 !important;
  }
  .c56,  .oc-c56::before  {
    background-color:  #f80 !important;
  }
  .go-c56  {
    color:  #f80 !important;
  }
  .c57,  .oc-c57::before  {
    background-color:  #d0b0a0 !important;
  }
  .go-c57  {
    color:  #d0b0a0 !important;
  }
  .c58,  .oc-c58::before  {
    background-color:  #bbf !important;
  }
  .go-c58  {
    color:  #bbf !important;
  }
  .c59,  .oc-c59::before  {
    background-color:  #9a6 !important;
  }
  .go-c59  {
    color:  #9a6 !important;
  }
  .c60,  .oc-c60::before  {
    background-color:  #0b0 !important;
  }
  .go-c60  {
    color:  #0b0 !important;
  }
  .c61,  .oc-c61::before  {
    background-color:  #3f3 !important;
  }
  .go-c61  {
    color:  #3f3 !important;
  }
  .c62,  .oc-c62::before  {
    background-color:  #dfd !important;
  }
  .go-c62  {
    color:  #dfd !important;
  }
  .c63,  .oc-c63::before  {
    background-color:  #ffebde !important;
  }
  .go-c63  {
    color:  #ffebde !important;
  }
  .c64,  .oc-c64::before  {
    background-color:  #c9c !important;
  }
  .go-c64  {
    color:  #c9c !important;
  }
  .c65,  .oc-c65::before  {
    background-color:  #f6eef6 !important;
  }
  .go-c65  {
    color:  #f6eef6 !important;
  }
  .c66,  .oc-c66::before  {
    background-color:  #a6a !important;
  }
  .go-c66  {
    color:  #a6a !important;
  }
  .c67,  .oc-c67::before  {
    background-color:  #d8d !important;
  }
  .go-c67  {
    color:  #d8d !important;
  }
  .c68,  .oc-c68::before  {
    background-color:  #f00 !important;
  }
  .go-c68  {
    color:  #f00 !important;
  }
  .c69,  .oc-c69::before  {
    background-color:  #c00 !important;
  }
  .go-c69  {
    color:  #c00 !important;
  }
  .c70,  .oc-c70::before  {
    background-color:  #e00 !important;
  }
  .go-c70  {
    color:  #e00 !important;
  }
  .c71,  .oc-c71::before  {
    background-color:  #dfdfdf !important;
  }
  .go-c71  {
    color:  #dfdfdf !important;
  }
  .c72,  .oc-c72::before  {
    background-color:  #f7f7f7 !important;
  }
  .go-c72  {
    color:  #f7f7f7 !important;
  }
  @media only screen and (max-width: 1240px)  {
    .dc13,  .dc14,  .grid-3  {
    display:  none;
  }
  }
  @media only screen and (max-width: 1080px)  {
    .left-frame-top,  .left-frame  {
    width:  170px;
    min-width:  170px;
  }
  .scroll-top a  {
    width:  170px;
  }
  .dc12,  .dc11,  .dc10,  .grid-1  {
    display:  none;
  }
  .left-frame  {
    padding-top:  70px;
  }
  .content  {
    padding-left:  40px;
  }
  .footer  {
    padding-left:  40px;
  }
  .buttons a  {
    margin:  0 6px 12px 6px;
  }
  .bar-1,  .bar-6  {
    width:  100px;
  }
  .bar-2,  .bar-7  {
    width:  100px;
  }
  .bar-3,  .bar-8  {
    width:  100px;
  }
  .corner-bg,  .corner,  .top-corner-bg,  .top-corner,  .block-2,  .block-3  {
    width:  100px;
  }
  }
  @media only screen and (max-width: 890px)  {
    .left-frame-top,  .left-frame  {
    width:  130px;
    min-width:  130px;
  }
  .left-frame-top  {
    border-radius:  0 0 0 60px;
  }
  .left-frame  {
    border-radius:  60px 0 0 0;
  }
  .scroll-top a  {
    width:  130px;
    padding:  10px 0 0 0;
    text-align:  center;
  }
  .sutrebor  {
    margin:  4px 4px 19px 69px;
  }
  .bar-1,  .bar-6  {
    width:  100px;
  }
  .bar-2,  .bar-7  {
    width:  80px;
  }
  .bar-3,  .bar-8  {
    width:  80px;
  }
  .corner-bg,  .corner,  .top-corner-bg,  .top-corner  {
    width:  100px;
  }
  .block-2,  .block-3  {
    width:  80px;
  }
  .block-4-floatbar  {
    width:  200px;
  }
  .bar-4-inside  {
    width:  140px;
  }
  .bar-9-inside  {
    width:  140px;
  }
  .content  {
    padding-left:  30px;
  }
  .footer  {
    padding-left:  30px;
  }
  blockquote  {
    margin-right:  0;
    margin-left:  25px;
  }
  .cut-out-top  {
    margin-left:  85px;
  }
  .cut-out  {
    margin-left:  85px;
  }
  .cut-out-float  {
    margin-left:  85px;
  }
  .dc7,  .dc8,  .dc9,  .grid-2  {
    display:  none;
  }
  .pics-right,  .pics-left  {
    float:  none;
    margin:  20px 0 20px 0;
  }
  .pics-right img,  .pics-left img  {
    display:  block;
    margin:  0 auto;
  }
  }
  @media only screen and (max-width: 790px)  {
    .dc5,  .dc6,  .grid-4  {
    display:  none;
  }
  }
  @media only screen and (max-width: 700px)  {
    .left-frame-top,  .left-frame  {
    width:  110px;
    min-width:  110px;
  }
  .left-frame-top  {
    border-radius:  0 0 0 50px;
  }
  .left-frame  {
    border-radius:  50px 0 0 0;
  }
  .scroll-top a  {
    width:  110px;
    height:  110px;
  }
  .top-corner-bg,  .top-corner  {
    width:  100px;
  }
  .bar-panel  {
    height:  16px;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-4,  .bar-6,  .bar-7,  .bar-8,  .bar-9  {
    height:  16px;
  }
  .block-4-floatbar  {
    width:  110px;
  }
  .block-2,  .block-3  {
    width:  70px;
  }
  .bar-2,  .bar-7  {
    width:  70px;
  }
  .bar-3,  .bar-8  {
    width:  70px;
  }
  .bar-4  {
    padding-top:  8px;
  }
  .bar-4-inside  {
    width:  100px;
    height:  8px;
    border-radius:  0 5px 0 0;
  }
  .bar-9-inside  {
    width:  100px;
    height:  8px;
    border-radius:  0 0 5px 0;
  }
  }
  @media only screen and (max-width: 500px)  {
    body  {
    padding:  0;
  }
  .wrap  {
    padding-left:  0;
  }
  .left-frame-top,  .left-frame  {
    width:  60px;
    min-width:  60px;
    font-size:  .8rem;
  }
  .left-frame-top  {
    border-radius:  0 0 0 26px;
  }
  .left-frame  {
    border-radius:  26px 0 0 0;
  }
  .scroll-top a  {
    width:  60px;
    border-top:  5px solid #000;
  }
  .panel-1 a  {
    padding-top:  30px;
  }
  .panel-2  {
    padding-bottom:  15px;
    border-bottom:  5px solid #000;
    background-color:  #ddbbff;
  }
  .hop  {
    display:  none;
  }
  .top-corner-bg  {
    height:  25px;
  }
  .top-corner  {
    height:  25px;
    border-radius:  0 0 0 20px;
  }
  .corner  {
    border-radius:  20px 0 0 0;
  }
  .bar-panel  {
    height:  10px;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-4,  .bar-6,  .bar-7,  .bar-8,  .bar-9  {
    height:  10px;
  }
  .bar-1,  .bar-2,  .bar-3,  .bar-6,  .bar-7,  .bar-8  {
    border-right:  5px solid #000;
  }
  .panel-1,  .panel-3,  .panel-4,  .panel-5,  .panel-6,  .panel-7,  .panel-8  {
    border-bottom:  5px solid #000;
  }
  .panel-10  {
    border-top:  5px solid #000;
  }
  .runner  {
    height:  25px;
  }
  .bar-1,  .bar-6  {
    width:  60px;
  }
  .bar-2,  .bar-7  {
    width:  50px;
  }
  .bar-3,  .bar-8  {
    width:  50px;
  }
  .bar-4  {
    padding-top:  5px;
  }
  .bar-4-inside  {
    width:  60px;
    height:  5px;
  }
  .bar-9-inside  {
    width:  60px;
    height:  5px;
  }
  .corner-bg,  .corner,  .top-corner-bg,  .top-corner  {
    width:  60px;
    height:  25px;
  }
  .block-2,  .block-3  {
    width:  50px;
    height:  25px;
  }
  .block-4  {
    padding-top:  15px;
  }
  .block-4b  {
    padding-top:  5px;
  }
  .block-4-floatbar  {
    width:  60px;
    height:  4px;
    background-color:  #ddbbff;
  }
  #gap  {
    margin-top:  5px;
  }
  .content  {
    padding:  0 0 20px 15px;
  }
  .footer  {
    padding:  0 0 20px 15px;
  }
  p.indent  {
    padding-left:  20px;
  }
  blockquote  {
    margin:  25px 0 25px 20px;
  }
  .lcars-bar-cutout  {
    margin:  0 auto;
    width:  80px;
    height:  8px;
  }
  .lcars-bar-slice-top  {
    margin:  30px auto -30px auto;
    width:  80px;
    height:  6px;
  }
  .lcars-bar-slice-bottom  {
    width:  80px;
  }
  }